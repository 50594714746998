import OSS from 'ali-oss'
import {
  getStsToken,
  stsToken
} from '@/api/manage'
import Vue from "vue";
import {ACCESS_TOKEN} from "@/store/mutation-types";

let stsInfo = {
  accessKeyId: "LTAI5t9Y5tRx71bxpjKhZtNz",
  accessKeySecret: "1Fu8nMJgboxevNixh58S4xpPL5zS7G",
  securityToken: null,
  bucket: "yihlengyun",
  basePath: null,
  requestId: null,
  host: null
}
export function put (name, File) {
  let that = this;
  let token = Vue.ls.get(ACCESS_TOKEN)

  try {
    return new Promise((resolve, reject) => {
      if (!stsInfo.securityToken) {
        return getStsToken().then(result => {
          stsInfo = result
          resolve(stsInfo)
        })
      } else {
        resolve(stsInfo)
      }
    }).then(stsInfo => {
      const client = new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: stsInfo.accessKeyId,
        accessKeySecret: stsInfo.accessKeySecret,
        bucket: stsInfo.bucket,
        stsToken: stsInfo.securityToken,
        secure:true
      })
      return client.put(stsInfo.basePath + name, File)
    })
  } catch (e) {
    console.log(e)
  }
}

export function ossFileUrl(filename){
  const ossDomain = 'https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/';
  return ossDomain + filename;
}