<style lang="less" scoped>
</style>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="4" :sm="24">
            <a-form-item label="客户名称">
              <a-input v-model="queryParam.nicknameLike" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="签约人">
              <a-input v-model="queryParam.signatoryLike" placeholder="" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_customer2_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 1500, y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:but_customer2_edit>编辑&ensp;|&ensp;</a>
          <a @click="updateStatus(record)" v-action:but_customer2_disable>{{ record.status == 1 ? '禁用' : '启用' }}</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addCustomer"
      title="新增/编辑"
      :width="450"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: '420px' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="客户名称"
            :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['nickname', { rules: [{ required: true, message: '请输入名称' }] }]"
              name="nickname"
            />
          </a-form-item>

          <a-form-item
            label="签约人"
            :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select
              show-search
              placeholder="请选择"
              :filter-option="filterOption"
              @change="handleChange"
              v-decorator="['signatoryId']"
            >
              <a-select-option v-for="(item, index) in optionssignatory" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="报价标签"
            :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select placeholder="请选择报价" @change="handleChange1" v-decorator="['quotationId']">
              <a-select-option v-for="(item, index) in optionsquoted" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="合同"
            :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <!--            <a-upload-->
            <!--              name="file"-->
            <!--              :max-count="1"-->
            <!--              :show-upload-list="false"-->
            <!--              :headers="headers"-->
            <!--              @change="uploadChange"-->
            <!--              action="/service_provider/customer/uploadContractFile"-->
            <!--            >-->
            <a-upload
              name="file"
              :max-count="1"
              class="avatar-uploader"
              :show-upload-list="false"
              :customRequest="customRequest"
              @change="uploadChange"
              style="width: 100%"
            >
              <a-button id="showfilename" style="width: 100%">
                <!--                <upload-outlined></upload-outlined>-->
                {{ thisCustomr.contractFile == null ? '上传文件' : thisCustomr.contractFile }}
              </a-button>
            </a-upload>
          </a-form-item>

          <a-form-item
            label="结算方式"
            :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select placeholder="请选择结算类型" @change="handleChange2" v-decorator="['settlementType']">
              <a-select-option value="1">现付</a-select-option>
              <a-select-option value="2">月结</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
            <a-button style="margin-left: 230px; margin-right: 8px" @click="addCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { UploadOutlined } from '@ant-design/icons-vue'
import Vue, { defineComponent, ref } from 'vue'
import { UploadChangeParam } from 'ant-design-vue'
import {
  findPageCustomer,
  updateCustomer,
  saveCustomer,
  deleteCustomer,
  getUserList,
  quotationCustomerPriceList,
} from '@/api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'

export default {
  name: 'TableList',
  components: {
    STable,
    UploadOutlined,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      idi: 2,
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '识别码',
          width: '150px',
          key: 'code',
          dataIndex: 'code',
        },
        {
          title: '客户名称',
          width: '150px',
          key: 'nickname',
          dataIndex: 'nickname',
        },
        {
          title: '签约人',
          width: '150px',
          key: 'signatory',
          dataIndex: 'signatory',
        },
        {
          title: '合同',
          width: '150px',
          key: 'contractFile',
          dataIndex: 'contractFile',
        },
        {
          title: '状态',
          width: '150px',
          key: 'status',
          dataIndex: 'status',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        param.type = 2
        return findPageCustomer(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      optionssignatory: [], //签约人候选[{ value: 'jack0', label: 'Jack' },{ value: 'lucy0', label: 'Lucy' }]
      optionsquoted: [], //报价标签候选[{ value: 1, label: '报价1' },{ value: 2, label: '报价2' }]
      optionssettle: [
        { value: 1, label: '现付' },
        { value: 2, label: '月结' },
      ], //结算方式候选
      fileList: [],
      thisCustomr: {},
      defaultCustomr: {},
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      let that = this
      this.visible_add = true
      this.thisCustomr = {}
      this.tabObj = record
      this.addFlag = false
      //初始化编辑框内容
      this.thisCustomr.contractFile = record.contractFile

      //初始化签约人候选数据
      //初始化报价列表数据
      this.$nextTick(() => {
        getUserList({ status: 1 })
          .then((res) => {
            if (res != null && res.result != null) {
              that.optionssignatory = []
              for (let i in res.result) {
                that.optionssignatory.push({ value: res.result[i].id + '', label: res.result[i].name })
              }
            }
          })
          .then(() => {
            that.form.setFieldsValue({
              nickname: record.nickname,
              settlementType: record.settlementType + '',
              signatoryId: record.signatoryId + '',
              contractFile: record.contractFile,
            })
          })
        quotationCustomerPriceList({ customerType: 1 })
          .then((res) => {
            if (res != null && res.result != null) {
              that.optionsquoted = []
              for (let i in res.result) {
                that.optionsquoted.push({ value: res.result[i].id, label: res.result[i].name })
              }
            }
          })
          .then(
            that.form.setFieldsValue({
              quotationId: record.quotationId,
            })
          )
      })
    },
    handleDel(record) {
      deleteCustomer(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateCustomer({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    handleOk() {},
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addCancel() {
      this.visible_add = false
    },
    handleAdd() {
      let that = this
      this.visible_add = true
      this.tabObj = {}
      this.addFlag = true
      this.thisCustomr = {}
      //初始化签约人候选数据
      getUserList({ status: 1 }).then((res) => {
        if (res != null && res.result != null) {
          that.optionssignatory = []
          for (let i in res.result) {
            that.optionssignatory.push({ value: res.result[i].id, label: res.result[i].name })
          }
        }
      })
      //初始化报价列表数据
      quotationCustomerPriceList({ customerType: 1 }).then((res) => {
        if (res != null && res.result != null) {
          that.optionsquoted = []
          for (let i in res.result) {
            that.optionsquoted.push({ value: res.result[i].id, label: res.result[i].name })
          }
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.contractFile = this.thisCustomr.contractFile
          values.type = 2
          if (this.addFlag) {
            saveCustomer(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            values.id = this.tabObj.id
            updateCustomer(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    handleChange(value) {
      this.thisCustomr.signatoryId = value
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange1(value) {
      this.thisCustomr.quotedId = value
    },
    handleChange2(value) {
      this.thisCustomr.settlementType = value
    },
    uploadChange(info) {
      let that = this
      // if (info.file.status === 'uploading') {
      //   this.loading = true
      //   return;
      // }
      // if (info.file.status === 'done') {
      //   let fileName = info.file.response.result
      //   this.thisCustomr.contractFile = fileName
      //   document.getElementById("showfilename").innerText = fileName
      // }
    },
    customRequest(action) {
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.thisCustomr.contractFile = fileName
          document.getElementById('showfilename').innerText = fileName
        })
        .catch((e) => {
          console.info(e)
        })
    },
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-label {
  text-align: left;
}
/deep/ .ant-upload {
  width: 100% !important;
}
</style>