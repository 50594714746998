import { axios } from '@/utils/request'

const api = {
  user: '/service_provider/user/findList',
  role: '/role',
  service: '/service',
  permission: '/permission',
  permissionNoPager: '/permission/no-pager',
  orgTree: '/service_provider/permition/findTree',
  getOrgPermTree: '/service_provider/permition/findTree',
  getRolePermTree: '/service_provider/permition/findRolePermition',
  bindRolePermition: '/service_provider/permition/bindRolePermition',
  findPageUser: '/service_provider/user/findPage',
  deleteUser: '/service_provider/user/deleteUser',
  bindUserRole: '/service_provider/role/bindUserRole',
  countWaitingOrder: '/service_provider/permition/findTree'
}

export default api

// export function getUserList (parameter) {
//   return axios({
//     url: api.user,
//     method: 'get',
//     params: parameter
//   })
// }

export function getRoleList (parameter) {
  return axios({
    url: "/service_provider/role/findList",
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function findPageRole (parameter) {
  return axios({
    url: "/service_provider/role/findPage",
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function getUserRoleList (parameter) {
  return axios({
    url: "/service_provider/role/findRolesByUser",
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function getServiceList (parameter) {
  return axios({
    url: api.service,
    method: 'get',
    params: parameter
  })
}

export function getPermissions (parameter) {
  return axios({
    url: api.permissionNoPager,
    method: 'get',
    params: parameter
  })
}

export function findPermitionByCode (parameter) {
  return axios({
    url: '/service_provider/permition/findPermitionByCode',
    method: 'post',
    data: parameter
  })
}

export function getOrgPermTree (parameter) {
  return axios({
    url: api.getOrgPermTree,
    method: 'post',
    data: parameter
  })
}

export function getRolePermTree (parameter) {
  return axios({
    url: api.getRolePermTree,
    method: 'post',
    data: parameter
  })
}

export function bindRolePermition (parameter) {
  return axios({
    url: api.bindRolePermition,
    method: 'post',
    data: parameter
  })
}
// id == 0 add     post
// id != 0 update  put
export function saveService (parameter) {
  return axios({
    url: api.service,
    method: parameter.id === 0 ? 'post' : 'put',
    data: parameter
  })
}

export function saveRole (parameter) {
  return axios({
    url: "/service_provider/role/saveRole",
    method: 'post',
    data: parameter
  })
}
export function saveUser (parameter) {
  return axios({
    url: "/service_provider/user/saveOrgUser",
    method: 'post',
    data: parameter
  })
}
export function updateUser (parameter) {
  return axios({
    url: "/service_provider/user/updateUser",
    method: 'post',
    data: parameter
  })
}
export function updateUserStatus (parameter) {
  return axios({
    url: "/service_provider/user/updateUserStatus",
    method: 'post',
    data: parameter
  })
}
export function updateUserMobile (parameter) {
  return axios({
    url: "/service_provider/user/updateMobile",
    method: 'post',
    data: parameter
  })
}
export function updatePasswd (parameter) {
  return axios({
    url: "/service_provider/user/updatePassWord",
    method: 'post',
    data: parameter
  })
}

export function updateRole (parameter) {
  return axios({
    url: "/service_provider/role/updateRole",
    method: 'post',
    data: parameter
  })
}


export function deleteRole (parameter) {
  return axios({
    url: "/service_provider/role/deleteRole",
    method: 'post',
    data: parameter
  })
}

export function findPageUser (parameter) {
  return axios({
    url: "/service_provider/user/findPage",
    method: 'post',
    data: parameter
  })
}

export function deleteUser (parameter) {
  return axios({
    url: "/service_provider/user/deleteUser",
    method: 'post',
    data: parameter
  })
}

export function bindUserRole (parameter) {
  return axios({
    url: "/service_provider/role/bindUserRole",
    method: 'post',
    data: parameter
  })
}

export function getUserInfo (parameter) {
  return axios({
    url: "/service_provider/user/getUserInfo",
    method: 'post',
    data: parameter
  })
}

export function countMes (parameter) {
  return axios({
    url: "/service_provider/message/count",
    method: 'post',
    data: parameter
  })
}

export function findPageMessage (parameter) {
  return axios({
    url: "/service_provider/message/findPage",
    method: 'post',
    data: parameter
  })
}
export function findListMessage (parameter) {
  return axios({
    url: "/service_provider/message/findList",
    method: 'post',
    data: parameter
  })
}
export function promptList (parameter) {
  return axios({
    url: "/service_provider/message/promptList",
    method: 'post',
    data: parameter
  })
}

export function countWaitingOrder (parameter) {
  return axios({
    url: "/service_provider/message/count",
    method: 'post',
    data: parameter
  })
}
export function updateMessage (parameter) {
  return axios({
    url: "/service_provider/message/update",
    method: 'post',
    data: parameter
  })
}

export function findPageNotice (parameter) {
  return axios({
    url: "/service_provider/notice/findPage",
    method: 'post',
    data: parameter
  })
}
export function findPagePlatformMessage (parameter) {
  return axios({
    url: "/service_provider/platformmessage/findPage",
    method: 'post',
    data: parameter
  })
}

export function findListNotice (parameter) {
  return axios({
    url: "/service_provider/notice/findList",
    method: 'post',
    data: parameter
  })
}

export function findPageWarehouse (parameter) {
  return axios({
    url: "/service_provider/warehouse/findPage",
    method: 'post',
    data: parameter
  })
}

export function findListWarehouse (parameter) {
  return axios({
    url: "/service_provider/warehouse/findList",
    method: 'post',
    data: parameter
  })
}

export function saveWarehouse (parameter) {
  return axios({
    url: "/service_provider/warehouse/saveWarehouse",
    method: 'post',
    data: parameter
  })
}

export function updateWarehouse (parameter) {
  return axios({
    url: "/service_provider/warehouse/updateWarehouse",
    method: 'post',
    data: parameter
  })
}

export function deleteWarehouse (parameter) {
  return axios({
    url: "/service_provider/warehouse/deleteWarehouse",
    method: 'post',
    data: parameter
  })
}


export function findPageCustomer (parameter) {
  return axios({
    url: "/service_provider/customer/findPage",
    method: 'post',
    data: parameter
  })
}
export function findListCustomer (parameter) {
  return axios({
    url: "/service_provider/customer/findList",
    method: 'post',
    data: parameter
  })
}

export function saveCustomer (parameter) {
  return axios({
    url: "/service_provider/customer/save",
    method: 'post',
    data: parameter
  })
}

export function updateCustomer (parameter) {
  return axios({
    url: "/service_provider/customer/update",
    method: 'post',
    data: parameter
  })
}

export function deleteCustomer (parameter) {
  return axios({
    url: "/service_provider/customer/delete",
    method: 'post',
    data: parameter
  })
}
export function settop (parameter) {
  return axios({
    url: "/service_provider/customer/settop",
    method: 'post',
    data: parameter
  })
}


export function getUserList (parameter) {
  return axios({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function quotationCustomerPriceList (parameter) {
  return axios({
    url: "/service_provider/quotationCustomerPrice/findList",
    method: 'post',
    data: parameter
  })
}

export function getStsToken () {
  return axios({
    url: "/service_provider/sts/getCredit",
    method: 'get'
  })
}

export function orderFindPage (parameter) {
  return axios({
    url: "/service_provider/order/findPage",
    method: 'post',
    data: parameter
  })
}

export function findPageDriver (parameter) {
  return axios({
    url: "/service_provider/driver/findPage",
    method: 'post',
    data: parameter
  })
}

export function saveDriver (parameter) {
  return axios({
    url: "/service_provider/driver/saveDriver",
    method: 'post',
    data: parameter
  })
}

export function updateDriver (parameter) {
  return axios({
    url: "/service_provider/driver/updateDriver",
    method: 'post',
    data: parameter
  })
}
export function deleteDriver (parameter) {
  return axios({
    url: "/service_provider/driver/deleteDriver",
    method: 'post',
    data: parameter
  })
}
export function findListDriver (parameter) {
  return axios({
    url: "/service_provider/driver/findList",
    method: 'post',
    data: parameter
  })
}

//更新提货到库地址
export function updateOrderWarehouse (parameter) {
  return axios({
    url: "/service_provider/order/updateOrderWarehouse",
    method: 'post',
    data: parameter
  })
}
//提货调度
export function loadDispacth (parameter) {
  return axios({
    url: "/service_provider/order/loadDispacth",
    method: 'post',
    data: parameter
  })
}
//倒仓调度
export function changewareDispacth (parameter) {
  return axios({
    url: "/service_provider/order/changewareDispacth",
    method: 'post',
    data: parameter
  })
}
//撤销提货调度
export function unLoadDispacth (parameter) {
  return axios({
    url: "/service_provider/order/unLoadDispacth",
    method: 'post',
    data: parameter
  })
}
//更新运输运单号
export function batchTransDispacth (parameter) {
  return axios({
    url: "/service_provider/order/updateTransportNo",
    method: 'post',
    data: parameter
  })
}

//更新运输状态
export function updateTransStatus (parameter) {
  return axios({
    url: "/service_provider/order/updateTransStatus",
    method: 'post',
    data: parameter
  })
}
//更新运输状态
export function updateChangewareStatus (parameter) {
  return axios({
    url: "/service_provider/order/updateChangewareStatus",
    method: 'post',
    data: parameter
  })
}

//更新运输状态
export function bindVehicle (parameter) {
  return axios({
    url: "/service_provider/driver/bindVehicle",
    method: 'post',
    data: parameter
  })
}

//车辆查询列表
export function findPageVehicle (parameter) {
  return axios({
    url: "/service_provider/vehicle/findPage",
    method: 'post',
    data: parameter
  })
}
//车辆保存
export function saveVehicle (parameter) {
  return axios({
    url: "/service_provider/vehicle/saveVehicle",
    method: 'post',
    data: parameter
  })
}
//更新车辆信息
export function updateVehicle (parameter) {
  return axios({
    url: "/service_provider/vehicle/updateVehicle",
    method: 'post',
    data: parameter
  })
}
//物理删除车辆
export function deleteVehicle (parameter) {
  return axios({
    url: "/service_provider/vehicle/deleteVehicle",
    method: 'post',
    data: parameter
  })
}
//车辆下拉选项
export function findListVehicle (parameter) {
  return axios({
    url: "/service_provider/vehicle/findList",
    method: 'post',
    data: parameter
  })
}

//整车调度
export function tltTransportNo (parameter) {
  return axios({
    url: "/service_provider/order/tltTransportNo",
    method: 'post',
    data: parameter
  })
}
//撤销整车调度
export function untltTransportNo (parameter) {
  return axios({
    url: "/service_provider/order/untltTransportNo",
    method: 'post',
    data: parameter
  })
}

//异常录入
export function saveExcepton(parameter) {
  return axios({
    url: "/service_provider/order-exception/saveExcepton",
    method: 'post',
    data: parameter
  })
}

//省市县数据
export function getGeoAddress(parameter) {
  return axios({
    url: "/service_provider/geoCascader/findList",
    method: 'post',
    data: parameter
  })
}

//入库
export function updateInWarehouseStatus(parameter) {
  return axios({
    url: "/service_provider/order/updateInWarehouseStatus",
    method: 'post',
    data: parameter
  })
}

//评定
export function leveljudge(parameter) {
  return axios({
    url: "/service_provider/org/leveljudge",
    method: 'post',
    data: parameter
  })
}

//查询企业
export function getOrg() {
  return axios({
    url: "/service_provider/org/getOrg",
    method: 'get'
  })
}
//整车运单详情
export function tltTransportInfo(parameter) {
  return axios({
    url: "/service_provider/order/tltTransportInfo",
    method: 'post',
    data: parameter
  })
}
//异常录入
export function saveReceipt(parameter) {
  return axios({
    url: "/service_provider/order/saveReceipt",
    method: 'post',
    data: parameter
  })
}
export function findListCarrier(parameter) {
  return axios({
    url: "/service_provider/carrier/findList",
    method: 'post',
    data: {}
  })
}
//工作台工作区统计
export function statisticswork(parameter) {
  return axios({
    url: "/service_provider/statistics/pending",
    method: 'post',
    data: {}
  })
}
//工作台数据区统计
export function statisticsdata(parameter) {
  return axios({
    url: "/service_provider/statistics/statisticsdata",
    method: 'post',
    data: {}
  })
}
//工作台企业分组统计
export function statisticcustomergroup(parameter) {
  return axios({
    url: "/service_provider/statistics/qyCuStatistics",
    method: 'post',
    data: parameter
  })
}
//工作台平台消息列表
export function statisticsmessage(parameter) {
  return axios({
    url: "/service_provider/platformmessage/findList",
    method: 'post',
    data: parameter
  })
}
//工作台广告位
export function statisticsbanner(parameter) {
  return axios({
    url: "/service_provider/banner/findList",
    method: 'post',
    data: parameter
  })
}

//行政区划数据
export function geoCascaderList(parameter) {
  return axios({
    url: "/service_provider/orgUnserverArea/geoCascaderList",
    method: 'post',
    data: parameter
  })
}

export function findPageUnserverArea(parameter) {
  return axios({
    url: "/service_provider/orgUnserverArea/findPage",
    method: 'post',
    data: parameter
  })
}

export function saveUnserverArea(parameter) {
  return axios({
    url: "/service_provider/orgUnserverArea/save",
    method: 'post',
    data: parameter
  })
}

export function delUnserverArea(parameter) {
  return axios({
    url: "/service_provider/orgUnserverArea/delete",
    method: 'post',
    data: parameter
  })
}